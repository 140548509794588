import React, { Component } from 'react';
import styled from 'styled-components'
import { Colors } from '../colors/Colors';
import { GoQuote } from "react-icons/go";
import { FaStar } from 'react-icons/fa';

const Container = styled.div`
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    box-shadow: 0 2px 8px 0 rgba(179,176,166,0.22);

    border-radius: 7px;
    padding: 20px 30px 20px 20px;
    max-width: 700px;
    margin: 0 auto;

    @media (max-width: 950px) {
        margin: 0 20px;
    }

    @media (max-width: 500px) {
        margin: 0 10px;

    }

    @media (max-width: 400px) {
        margin: 0 10px;
        padding: 5px;
        box-shadow: none;
        border: none;


    }

    /* margin-left: 30px;
    margin-right: 30px; */
`
const Stars = styled.div`
    margin-bottom: 20px;

    @media (max-width: 400px) {
        display: flex;
        justify-content: center;
    }
`
const Quotation = styled.div`
    @media (max-width: 950px) {
        display: none;
    }
`
const Review = styled.div`
margin-bottom: 15px;
    p {
        font-size: 16px;
        color: #1D1D1D;
        line-height: 150%;
        font-style: italic;

        @media (max-width: 950px) {
            font-size: 15px;

        }

        @media (max-width: 400px) {
        text-align: center;
    }

    }
`
const Author = styled.p`
font-weight: bold;
font-size: 15px;
color: #000000;


@media (max-width: 400px) {
        text-align: center;
    }
`
const Google = styled.span`
  color: #B1B1B1;
  font-weight: 600;

`;

const TestimonialCard = props => (
    <Container>
        <Stars>
            <FaStar color={'#F7CA18'} /><FaStar color={'#F7CA18'} /><FaStar color={'#F7CA18'} /><FaStar color={'#F7CA18'} /><FaStar color={'#F7CA18'} />

        </Stars>
        {/* <Quotation>
            <GoQuote style={{ marginBottom: '10px' }} size={38} color={Colors.gold} />

        </Quotation> */}
        <Review dangerouslySetInnerHTML={{ __html: props.review }} />
        <Author>-{props.author} • <Google>from Google Reviews</Google></Author>
    </Container>
)

export default TestimonialCard